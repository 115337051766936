import {get} from 'lodash';
import type React from 'react';
import type {FormikProps} from 'formik';
import {type ChangeEvent, useRef} from 'react';
import useScrollErrorIntoView from 'src/utils/useScrollErrorIntoView';
import FormInputWrapper from './FormInputWrapper';

export type FormTextInputProps = {
  prefix?: string;
  id: string;
  errorsId?: string;
  brandStyle: BrandStyle;
  label: string;
  helperText?: string;
  optional?: boolean;
  type?: 'text' | 'number' | 'password' | 'tel' | 'email';
  style?: React.CSSProperties;
  formikProps: FormikProps<unknown>;
  limit?: number;
  disabled?: boolean;
  autoFocus?: boolean;
  autoComplete?: 'off';
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  maxLength?: number;
  placeholder?: string;
  className?: string;
};

const FormTextInput = ({
  prefix,
  id,
  errorsId,
  brandStyle,
  label,
  helperText,
  optional,
  type,
  style,
  formikProps,
  disabled,
  autoComplete,
  autoFocus,
  onKeyPress,
  maxLength,
  placeholder,
  className
}: FormTextInputProps) => {
  const containerRef = useRef(undefined);

  useScrollErrorIntoView(containerRef, id, formikProps);

  const value = get(formikProps.values, id);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const validValue = e.target.value.substring(0, maxLength || Number.MAX_VALUE);

    e.target.value = validValue;

    formikProps.handleChange(e);
  };

  return (
    <FormInputWrapper
      className={className}
      containerRef={containerRef}
      brandStyle={brandStyle}
      id={id}
      label={label}
      errorMessage={get(formikProps.errors, errorsId || id)}
      helperText={helperText}
      optional={optional}
      touched={get(formikProps.touched, id)}
      style={style}
    >
      <div className="highlight-block flex items-baseline overflow-hidden">
        {prefix && <span className="text-lg text-[--gray-text] whitespace-pre leading-tight">{prefix}</span>}

        <input
          className="grow w-0 bg-transparent border-none text-lg pt-[2px] px-0 pb-[6px] leading-tight"
          autoFocus={autoFocus}
          disabled={disabled}
          type={type}
          onChange={onChange}
          onKeyPress={onKeyPress}
          value={value || ''}
          id={id}
          autoComplete={autoComplete}
          placeholder={placeholder}
        />
      </div>
    </FormInputWrapper>
  );
};

export default FormTextInput;
